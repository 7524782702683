import { useState, useEffect } from "react";

import "./signup.css";
import profileIcon from "../assets/Assets/Svgs/profileIcon.svg";
import eyeopen from "../assets/Assets/Images/eyeopen.png";
import eyeclose from "../assets/Assets/Images/eyeclose.png";
import Header from "../Components/Auth/Header";
import Title from "../Components/Title";
import { Link } from "react-router-dom";

import { toast } from "sonner";
import { Label } from "@/Components/ui/label";

import lock from "../assets/Assets/Images/pass-lock.png";

import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/Components/ui/input-otp";
import {
  useResetPassCodeMutation,
  useSendEmailMutation,
  useSendOtpMutation,
} from "@/App/services/forgotPasscodeApiSlice";
import { FormLoader } from "@/Components/loader";
import usePINValidator from "@/App/components/hooks/usePassCode";

const ForgotPasscode = () => {
  const [cardToShow, setCardToShow] = useState("email");
  const [userEmail, setEmail] = useState("");
  const [userOtp, setOtp] = useState("");
  const [newPassCode, setNewPassCode] = useState("");
  const [confirmNewPassCode, setConfirmNewPassCode] = useState("");

  const { validatePIN: validatePINnew } = usePINValidator();
  const { validatePIN: validatePINconfirm } = usePINValidator();

  function formatEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email is valid
    if (!emailRegex.test(email)) {
      return "Invalid email";
    }

    // Split the email into local part and domain part
    const [localPart, domain] = email.split("@");

    // Identify the position to start masking
    const startIndex = Math.max(0, Math.floor(localPart.length / 2) - 1);

    // Mask three consecutive letters starting from startIndex
    const maskedLocalPart =
      localPart.slice(0, startIndex) + "***" + localPart.slice(startIndex + 3);

    // Format the masked email
    const maskedEmail = `${maskedLocalPart}@${domain}`;

    return maskedEmail;
  }
  const [sendEmail, { isLoading: emailIsLoading }] = useSendEmailMutation();
  const [sendOtp, { isLoading: otpIsLoading }] = useSendOtpMutation();
  const [sendNewPassCode, { isLoading: restIsLoading }] =
    useResetPassCodeMutation();

  async function handleSubmitEmail(e) {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("email", userEmail);
      await sendEmail({ formData: formData })
        .unwrap()
        .then((data) => {
          if (data.success) {
            setCardToShow("otp");
          }
        })
        .catch((error) => {
          toast.error(error.data.message);
          console.log(error);
        });
    } catch (error) {
      toast.error(error?.data?.errors.email[0]);
    }
  }

  async function handleSendOtp(e) {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("email", userEmail);
      formData.append("code", userOtp);
      formData.append("type", "password_reset");
      await sendOtp({ formData: formData })
        .unwrap()
        .then((data) => {
          if (data.success) {
            setCardToShow("reset");
          }
        })
        .catch((error) => {
          toast.error(error.data.message);
          console.log(error);
        });
    } catch (error) {
      toast.error(error?.data?.message);
    }
  }

  async function handleSendReset(e) {
    e.preventDefault();
    const isValidNew = validatePINnew(newPassCode);
    const isValidConfirm = validatePINconfirm(confirmNewPassCode);
    // console.log(isValidNew, isValidConfirm);
    if (isValidNew[1] && isValidConfirm[1]) {
      if (newPassCode === confirmNewPassCode) {
        try {
          const formData = new FormData();
          formData.append("code", userOtp);
          formData.append("password", newPassCode);
          await sendNewPassCode({ formData: formData })
            .unwrap()
            .then((data) => {
              if (data.success) {
                setCardToShow("success");
              }
            })
            .catch((error) => {
              toast.error(error.data.message);
              console.log(error);
            });
        } catch (error) {
          toast.error(error?.data?.message);
        }
      } else {
        toast.error("Confirm new passcode correctly.");
      }
    } else {
      console.log(isValidNew)
      toast.error(isValidNew[0] || isValidConfirm[0]);
    }
  }

  const [showNew, setShowNew] = useState(false);
  function handleNew() {
    setShowNew(!showNew);
  }
  const [showConfirm, setShowConfirm] = useState(false);
  function handleConfirm() {
    setShowConfirm(!showConfirm);
  }
  return (
    <div className="h-[100%]  bg-cover bg-signup  min-h-screen flex flex-col bg-[#FFFDF2] overflow-hidden relative px-[5%] py-[1%] pb-6 lg:pb-2 max-[767px]:py-[5%] max-[767px]:min-h-[100vh]">
      <Header />
      <Title title={"Forgot Passcode"} />
      <div className="mt-[5vh]">
        {cardToShow === "email" ? (
          <form
            onSubmit={handleSubmitEmail}
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            className="  laptop:mt-[2.5%] max-[767px]:mt-[14%]  log-input tablet:mt-[10vh] flex flex-col mt-3 items-center  shadow-lg bg-white  pb-8 tablet:pb-16 laptop:pb-8 rounded-[20px] z-10  max-[767px]:w-[100%] max-[767px]:h-[70%]  max-[1520px]:mb-[5%] tablet:h-fit laptop:h-fit laptop:max-h-[45rem] laptop:min-h-fit  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] lg:overflow-y-scroll lg:overscroll-none"
          >
            <div className="pt-[8%] text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
              <img alt="profile" src={profileIcon} />
              <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">
                Forgot Password?
              </h1>
              <p>
                Resetting your passcode is easy. To get started, please provide
                the email address associated with your account.
              </p>
            </div>

            <div className=" max-[767px]:w-[90%] mt-5">
              <div className="bg-[#F9F7F3] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[767px]:w-[100%] max-[767px]:rounded-[10px]">
                <p className="text-[15px] font-semibold text-[#191B1E]">
                  Email Address
                </p>
                <input
                  value={userEmail}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-[#F9F7F3] text-[15px] font-semibold w-[100%] h-[30px] max-[767px]:text-[14px] max-[767px]:w-[100%]"
                  placeholder="Enter email"
                  type="email"
                />
              </div>

              <div className="mt-4">
                <button
                  // onClick={handleClick}
                  className="bg-[#1A6A73] text-center w-[100%] py-[4%] text-[white] rounded-[30px]"
                >
                  {emailIsLoading ? <FormLoader /> : "Send verification code"}
                </button>
              </div>
            </div>
          </form>
        ) : cardToShow === "otp" ? (
          <form
            onSubmit={handleSendOtp}
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            className="  laptop:mt-[2.5%] max-[767px]:mt-[14%] tablet:mt-[10vh] flex flex-col mt-3 items-center  shadow-lg bg-white  pb-8 tablet:pb-16 laptop:pb-8 rounded-[20px] z-10  max-[767px]:w-[100%] max-[767px]:h-[70%]  max-[1520px]:mb-[5%] tablet:h-fit laptop:h-fit laptop:max-h-[45rem] laptop:min-h-fit  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] lg:overflow-y-scroll lg:overscroll-none"
          >
            <div className="pt-[8%] text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
              <img alt="profile" src={profileIcon} />
              <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">
                Verification Code Sent
              </h1>
              <p>
                {`A one-time verification code has been sent to
                ${formatEmail(
                  userEmail
                )}. Please check your email and enter the code
                here.`}
              </p>
            </div>

            <div className=" w-[90%] mt-5">
              <InputOTP
                value={userOtp}
                onChange={(value) => setOtp(value)}
                className=""
                maxLength={4}
              >
                <InputOTPGroup className="*:bg-[#F5F5F6] space-x-4 *:h-[50px] mx-auto *:rounded-[6px] *:border-none *:font-bold ">
                  <InputOTPSlot index={0} />
                  <InputOTPSlot index={1} />
                  <InputOTPSlot index={2} />
                  <InputOTPSlot index={3} />
                </InputOTPGroup>
              </InputOTP>

              <div className="mt-4">
                <button
                  // onClick={handleClick}
                  className="bg-[#1A6A73] text-center w-[100%] py-[4%] text-[white] rounded-[30px]"
                >
                  {otpIsLoading ? <FormLoader /> : "Reset passcode"}
                </button>
              </div>
            </div>
          </form>
        ) : cardToShow === "reset" ? (
          <form
            onSubmit={handleSendReset}
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            className="  laptop:mt-[2.5%] max-[767px]:mt-[14%] tablet:mt-[10vh] flex flex-col mt-3 items-center  shadow-lg bg-white  pb-8 tablet:pb-16 laptop:pb-8 rounded-[20px] z-10  max-[767px]:w-[100%] max-[767px]:h-[70%]  max-[1520px]:mb-[5%] tablet:h-fit laptop:h-fit laptop:max-h-[45rem] laptop:min-h-fit  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] lg:overflow-y-scroll lg:overscroll-none"
          >
            <div className="pt-[8%] text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
              <img alt="profile" src={profileIcon} />
              <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">
                Reset Password
              </h1>
              <p>
                Now, let's create a secure password for your account. Please set
                up your passcode.
              </p>
            </div>

            <div className=" w-[90%] mt-4">
              <div className="space-y-2">
                <div>
                  <Label
                    htmlFor="name"
                    className="text-left text-[14px] capitalize text-[#0A0D14] leading-5 font-semibold"
                  >
                    New Password*
                  </Label>

                  <div
                    tabIndex="0"
                    className="border-2 border-[#E2E4E9] relative overflow-hidden  rounded-[10px] mt-1 w-full"
                  >
                    <img
                      className=" w-[20px] absolute top-1/2 left-3 transform -translate-y-1/2"
                      src={lock}
                      alt="show passcode"
                    />
                    <input
                      style={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      value={newPassCode}
                      onChange={(e) => setNewPassCode(e.target.value)}
                      id="name"
                      type={showNew ? "text" : "password"}
                      required
                      placeholder="********"
                      className="w-full   font-medium text-[14px] pl-[42px] py-[8px] focus:outline-red-500!  "
                    />
                    <button
                      type="button"
                      onClick={handleNew}
                      className="absolute top-1/2 right-3 transform -translate-y-1/2 "
                    >
                      <img
                        className=" w-[19px]"
                        src={showNew ? eyeopen : eyeclose}
                        alt="show password"
                      />
                    </button>
                  </div>
                </div>
                <div>
                  <Label
                    htmlFor="name"
                    className="text-left text-[14px] capitalize text-[#0A0D14] leading-5 font-semibold"
                  >
                    Confirm New Password*
                  </Label>

                  <div
                    tabIndex="0"
                    className="border-2 border-[#E2E4E9] relative overflow-hidden  rounded-[10px] mt-1 w-full"
                  >
                    <img
                      className=" w-[20px] absolute top-1/2 left-3 transform -translate-y-1/2"
                      src={lock}
                      alt="show passcode"
                    />
                    <input
                      style={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      value={confirmNewPassCode}
                      onChange={(e) => setConfirmNewPassCode(e.target.value)}
                      id="name"
                      type={showConfirm ? "text" : "password"}
                      required
                      placeholder="********"
                      className="w-full   font-medium text-[14px] pl-[42px] py-[8px] focus:outline-red-500!  "
                    />
                    <button
                      type="button"
                      onClick={handleConfirm}
                      className="absolute top-1/2 right-3 transform -translate-y-1/2 "
                    >
                      <img
                        className=" w-[19px]"
                        src={showConfirm ? eyeopen : eyeclose}
                        alt="show password"
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <button
                  // onClick={handleClick}
                  className="bg-[#1A6A73] text-center w-[100%] py-[4%] text-[white] rounded-[30px]"
                >
                  {restIsLoading ? <FormLoader /> : "Reset"}
                </button>
              </div>
            </div>
          </form>
        ) : (
          <form
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            className="  laptop:mt-[2.5%] max-[767px]:mt-[14%] tablet:mt-[10vh] flex flex-col mt-3 items-center  shadow-lg bg-white  pb-8 tablet:pb-16 laptop:pb-8 rounded-[20px] z-10  max-[767px]:w-[100%] max-[767px]:h-[70%]  max-[1520px]:mb-[5%] tablet:h-fit laptop:h-fit laptop:max-h-[45rem] laptop:min-h-fit  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] lg:overflow-y-scroll lg:overscroll-none"
          >
            <div className="pt-[8%] text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
              <img alt="profile" src={profileIcon} />
              <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">
                Success!
              </h1>
              <p className="mt-2">
                Your passcode has been reset. You can now log in to your account
                using your new passcode.
              </p>
            </div>

            <div className=" w-[90%] mt-4">
              <div className="mt-4">
                <Link to="/login">
                  <button
                    // onClick={handleClick}
                    className="bg-[#1A6A73] text-center w-[100%] py-[4%] text-[white] rounded-[30px]"
                  >
                    Login
                  </button>
                </Link>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgotPasscode;
