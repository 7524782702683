import { useState, useEffect, useMemo } from "react";
import ReactGA from "react-ga4";
import "./signup.css";
import stringSimilarity from "string-similarity";
import PatternBg from "../assets/Assets/Svgs/Pattern.svg";
// import logo from "../../../public/Assets/Svgs/logo.svg";
import profileIcon from "../assets/Assets/Svgs/profileIcon.svg";
import arrDown from "../assets/Assets/Images/arrow-down.png";
import search from "../assets/Assets/Images/search.png";

import chevron from "../assets/Assets/Images/chevron-down.png";
// import { getCountries } from "../../Api";

import SignupFormTwo from "../Components/SignupForms/SignupFormTwo";
import Header from "../Components/Auth/Header";
import Title from "../Components/Title";

import { signupDataContext } from "../Components/SignupForms/SignUpContext";
import { checkEmail,checkOtp, checkPhoneNumber, getCountries } from "@/Api";
// import countriesCodes from "./countryCodes";
import Loader, { FormLoader } from "@/Components/loader";
import { toast } from "sonner";
import SignupFormThree from "@/Components/SignupForms/SignupFormThree";
import SignupFormFour from "@/Components/SignupForms/SignupFormFour";
import SignupFormFive from "@/Components/SignupForms/SignupFormFive";
import SignupFormSix from "@/Components/SignupForms/SignupFormSix";
import SignupFormSeven from "@/Components/SignupForms/SignupFormSeven";
import SignupFormEight from "@/Components/SignupForms/SignupFormEight";
import SignupFormX from "@/Components/SignupForms/SignupFormX";
import SignupFormNine from "@/Components/SignupForms/SignupFormNine";
import SignUpCredentials from "@/Components/SignupForms/SignUpCredentials";
import SignupForm11 from "@/Components/SignupForms/SignupForm11";
import SignupFormOtp from "@/Components/SignupForms/SignupOtp";
import usePINValidator from "@/App/components/hooks/usePassCode";
const Signup = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const [signupData, setSignupData] = useState({
    name: "",
    email: "",
    password: "",
    gender: "Male",
    nationality: "",
    countries_lived_in: [],
    country: "",
    address: "",
    emirate_of_licensure: "",
    field: "",
    current_workplace: "",
    weekly_time_spent: "",
    role: "Therapist",
    phone_number: "",
    phone_code: "",
    languages_spoken: ["English"],
    title: "",
    interest_in_mentra: "",
    intended_weekly_time: "",
    heard_about_us: "",
    treatable_conditions: [],
    techniques_of_expertise: [],
    credentials: "",
    licence: "",
  });

  const [filled, setFilled] = useState(true);
  const [errMess, setErrMess] = useState(
    "All fields must be filled correctly!"
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [progress, setProgress] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [countriesCodes, setCountriesCode] = useState("");
  const [phoneLength, setPhoneLength] = useState(9);

  useEffect(() => {
    setProgress(1);
  }, [setProgress]);

  const [fullname, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [genderModal, setGenderModal] = useState(false);
  const [gender, setGender] = useState("Male");

  const [countries, setCountries] = useState([]);

  const [phoneCodeModal, setPhoneCodeModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState("972");
  const [activeCountry, setActiveCountry] = useState("united arab emirates");
  const [flag, setFlag] = useState("");
  const [phoneCodeSearch, setPhoneCodeSearch] = useState("");
  const [otp_check, setOtp_check] = useState(false);
  const { validatePIN: validatePINnew } = usePINValidator();
  

  function handlePhoneCodeModal() {
    setPhoneCodeModal(!phoneCodeModal);
  }

  useEffect(() => {
    async function verifyNumber() {
      try {
        const response = await checkPhoneNumber();
        const data = response.data.data;
        // console.log(data);
        setCountriesCode(data);
      } catch (error) {
        console.log(error);
        toast.error("Error fetching country codes");
      }
    }

    verifyNumber();
  }, []);

  // Effect to handle changes in maxLength
  useEffect(() => {
    if (number.length > phoneLength) {
      setNumber(number.slice(0, phoneLength));
    }
  }, [phoneLength, number]);

  function handlePhoneCode(value) {
    let countryName;
    if (value.name === "Venezuela (Bolivarian Republic of)") {
      countryName = "Venezuala";
    } else if (value.name === "Swaziland") {
      countryName = "Eswatini";
    } else if (value.name === "Syrian Arab Republic") {
      countryName = "Syria";
    } else if (value.name === "Côte d'Ivoire") {
      countryName = "ivory coast";
    } else {
      countryName = value.name;
    }
    const phone = countriesCodes
      .find((data) => data.name.toLowerCase() === value.name.toLowerCase())
      ?.phone_code?.toString();
    const phoneLengthArray = countriesCodes.find(
      (data) => data.name.toLowerCase() === value.name.toLowerCase()
    )?.phone_length;

    if (phoneLengthArray.length > 0) {
      const sortedNumbers = phoneLengthArray.sort((a, b) => b - a);
      const maxPhnoneLength = sortedNumbers[0];
      setPhoneLength(maxPhnoneLength);
    } else {
      setPhoneLength(10);
    }

    // Example search value

    // Find the best match for the search value
    const bestMatch = stringSimilarity.findBestMatch(
      countryName,
      countries.map((country) => country.name.common)
    ).bestMatch.target;

    // Use .find to locate the object with the best matching name
    const matchedCountry = countries.find(
      (country) => country.name.common === bestMatch
    );

    // console.log(matchedCountry);

    setPhoneCode(phone);
    setFlag(matchedCountry.flags.png);
    setPhoneCodeModal(false);
    setActiveCountry(countryName);
  }

  useEffect(() => {
    if (phoneCodeModal) {
      const input = document.getElementById("phoneCode");
      input.focus();
    }
  }, [phoneCodeModal]);

  const filteredPhoneCode = useMemo(() => {
    return phoneCodeSearch.trim() !== ""
      ? countriesCodes.filter((item) => {
          // console.log(item.name);
          const nameMatch =
            item.name
              ?.toLowerCase()
              ?.startsWith(phoneCodeSearch.toLowerCase()) ?? false;

          let countryCodeMatch;

          if (phoneCodeSearch.length >= 2 && item.country_code) {
            countryCodeMatch = item?.country_code
              .toLowerCase()
              .startsWith(phoneCodeSearch.toLowerCase());
          }

          return nameMatch || countryCodeMatch;
        })
      : countriesCodes;
  }, [phoneCodeSearch, countriesCodes]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getCountries();
        const data = response.data;
        // console.log(
        //   data.filter((data) => data.name.common.toLowerCase() === "ivory coast")
        // );

        // Sort the data by name and set it to the 'countries' state
        const sortedByName = [...data].sort((a, b) => {
          if (a.name.common < b.name.common) return -1;
          if (a.name.common > b.name.common) return 1;
          return 0;
        });

        const phone = countriesCodes
          .find((data) => data.name.toLowerCase() === "united arab emirates")
          ?.phone_code?.toString();

        const flag = data
          .filter(
            (data) => data.name.common.toLowerCase() === "united arab emirates"
          )
          .map((filteredData) => filteredData.flags.png);

        setFlag(flag);
        setPhoneCode(phone);
        setCountries(sortedByName);
      } catch (error) {
        console.error("Error fetching countries:", error.message);
      }
    };

    fetchCountries();
  }, [countriesCodes]);


  
   function handleMaxPinLength(event) {
    const newValue = event.target.value;
    // if (newValue.length <= maxLength) {
    //   setPassword(newValue);
    // }
    setPassword(newValue);
  }

  function handleGenderModal() {
    setGenderModal(!genderModal);
  }

  function handleGender(e) {
    const value = e.target.value;
    console.log(value);
    setGender(value);
    setGenderModal(false);
  }

  function handleFullName(e) {
    setFullName(e.target.value);
  }

  function handleNumber(e) {
    setNumber(e.target.value);
    // setPhoneCode(123)
    // console.log(phoneCode)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const pin = password;
    const isValidNew = validatePINnew(pin);
    if (fullname && number && gender && email && password) {
      setFilled(true);
      if (pin && isValidNew[1]) {
        console.log(isValidNew)
        setIsLoading(true);
        try {
          const dataToSend = { email: email };
          const response = await checkEmail(dataToSend);
          if (response.data.success) {
            toast.error(
              "The email address has already been used by another user."
            );
          }
        } catch (error) {
          setSignupData((prevData) => ({
            ...prevData,
            name: fullname,
            gender: gender,
            phone_number: number,
            email: email,
            password: password,
            phone_code: `+${phoneCode}`,
          }));
          const dataToSend = { name:fullname,email: email };
          let otp_check =  await  checkOtp(dataToSend);
          console.log(otp_check)
          if(otp_check){
            setOtp_check(otp_check);
          }
          setCurrentStep(2);
        } finally {
          setIsLoading(false);
        }
      } else {
        setFilled(false);
        toast.error(isValidNew[0]);
      }
    } else {
      setFilled(false);
      setErrMess("All fields must be filled correctly!");
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return null;
      case 2:
        return (
          <SignupFormOtp setProgress={setProgress} setStep={setCurrentStep} otp_check={otp_check} />
        );
      case 3:
        return (
          <SignupFormTwo setProgress={setProgress} setStep={setCurrentStep} />
        );
      case 4:
        return (
          <SignupFormThree setProgress={setProgress} setStep={setCurrentStep} />
        );
      case 5:
        return (
          <SignupFormFour setProgress={setProgress} setStep={setCurrentStep} />
        );
      case 6:
        return (
          <SignupFormFive setProgress={setProgress} setStep={setCurrentStep} />
        );
      case 7:
        return (
          <SignupFormSix setProgress={setProgress} setStep={setCurrentStep} />
        );
      case 8:
        return (
          <SignupFormSeven setProgress={setProgress} setStep={setCurrentStep} />
        );
      case 9:
        return (
          <SignupFormEight setProgress={setProgress} setStep={setCurrentStep} />
        );
      case 10:
        return (
          <SignupFormNine setProgress={setProgress} setStep={setCurrentStep} />
        );
      case 11:
        return (
          <SignUpCredentials
            setProgress={setProgress}
            setStep={setCurrentStep}
          />
        );
      case 12:
        return (
          <SignupFormX setProgress={setProgress} setStep={setCurrentStep} />
        );
      case 13:
        return <SignupForm11 setProgress={setProgress} />;
      default:
        return null;
    }
  };

  return countries.length > 0 && countriesCodes ? (
    <signupDataContext.Provider value={{ setSignupData, signupData }}>
      <div 
        className="h-[100%] bg-cover sign-in min-h-screen flex flex-col bg-[#FFFDF2] relative px-[5%] py-[1%] pb-6 lg:pb-2 max-[767px]:py-[5%] max-[767px]:min-h-[100vh]"
        style={{ backgroundImage: `url(${PatternBg})` }}
      >
        <Header setStep={setCurrentStep} step={currentStep} />
        <Title title={"Signup"} />

        <div className="h-[16px] p-[4px] mx-auto bg-white shadow-lg rounded-full laptop:mt-[2.5%] max-[767px]:mt-[14%] tablet:mt-[10vh] w-full tablet:w-[60vw] tablet:min-w-[520px] tablet:max-w-[522px]">
          <div
            className="h-full rounded-full bg-[#1A6A73]"
            style={{
              width: `${(progress / 12) * 100}%`,
              transition: "width 0.5s ease",
            }}
          ></div>
        </div>
        {currentStep !== 1 ? (
          renderStep()
        ) : (
          <form
            onSubmit={handleSubmit}
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            className="flex flex-col mt-3 items-center  shadow-lg bg-white  pb-8 rounded-[20px] z-10  max-[767px]:w-[100%] max-[767px]:h-[70%] max-[1520px]:mb-[5%] tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px]  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] lg:overflow-y-scroll lg:overscroll-none"
          >
            <div className="pt-[8%] laptop:pt-6 text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
              <img alt="profileIcon" src={profileIcon} />
              <h1 className="text-[24px]  font-bold max-[767px]:text-[20px]">
                Sign up as a Coach
              </h1>
              <p>Enter your details to proceed.</p>
            </div>
            {/* <button onClick={verifyNumber}>test phone api</button> */}

            <div className=" max-[767px]:w-[90%]">
              <p
                className={` text-red-700 w-full ${
                  filled ? "opacity-0" : "opacity-1"
                } tracking-wider text-center my-2`}
              >
                {errMess}
              </p>
              <div className="bg-[#F9F7F3] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
                <p className="text-[15px] font-semibold text-[#191B1E]">
                  Full Name
                </p>
                <input
                  type="text"
                  required
                  value={fullname}
                  onChange={handleFullName}
                  className="bg-[#F9F7F3] text-[15px] font-semibold w-[100%] h-[30px] max-[767px]:text-[14px] max-[767px]:w-[100%]"
                  placeholder="Enter fullname"
                />
              </div>
              <div className="bg-[#F9F7F3] w-[450px] text-[13px] mt-[3%] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
                <p className="text-[15px] font-semibold text-[#191B1E]">
                  Email Address
                </p>
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-[#F9F7F3] text-[15px] font-semibold w-[100%] h-[30px] max-[767px]:text-[14px] max-[767px]:w-[100%]"
                  placeholder="Enter email address"
                />
              </div>
              <div className="bg-[#F9F7F3] w-[450px] text-[13px] mt-[3%] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
                <p className="text-[15px] font-semibold text-[#191B1E]">
                  Password
                </p>

                <input
                  type="password"
                  required
                  value={password}
                  onChange={handleMaxPinLength}
                  className="bg-[#F9F7F3] text-[15px] font-semibold w-[100%] h-[30px] max-[767px]:text-[14px] max-[767px]:w-[100%]"
                  placeholder="Create a Password "
                />
              </div>

              <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
                <p className="text-[15px] mb-[2px] font-semibold text-[#191B1E]">
                  Mobile Number
                </p>
                <div className="flex relative justify-center items-center">
                  <div className="pr-2   h-fit ">
                    <div
                      onClick={handlePhoneCodeModal}
                      className="w-full cursor-pointer flex justify-center  items-center gap-2"
                    >
                      <div className="w-[32px] aspect-square flex justify-center items-center overflow-hidden rounded-[100%]">
                        <img src={flag} alt="" className="h-full" />
                      </div>
                      <h1 className=" text-[15px] max-[767px]:text-[14px] leading-5 text-[#010002] font-semibold">
                        +{phoneCode}
                      </h1>
                      <img src={chevron} className="w-[9.6px]" alt="menu" />
                    </div>
                    {phoneCodeModal ? (
                      <div
                        style={{
                          scrollbarWidth: "none",
                          msOverflowStyle: "none",
                        }}
                        className="w-full  overflow-y-scroll z-10 overscroll-none over flex flex-col justify-start items-start absolute bottom-[100%]  rounded-md h-[250px] bg-[#ffffff] border-2 "
                      >
                        <div className=" border-b-2 gap-3  flex w-full px-3 py-2 items-center">
                          <img src={search} alt="search" className="w-[20px]" />
                          <input
                            id="phoneCode"
                            value={phoneCodeSearch}
                            onChange={(e) => setPhoneCodeSearch(e.target.value)}
                            className="border-l-2 pl-4 font-medium text-zinc-500"
                            type="text"
                          />
                        </div>
                        {filteredPhoneCode.map((item, index) => (
                          <div
                            className="block  w-full"
                            key={index}
                            onClick={() => handlePhoneCode(item)}
                          >
                            <div
                              className={`flex justify-start px-4 cursor-pointer ${
                                activeCountry.toLowerCase() ===
                                item.name.toLowerCase()
                                  ? "bg-[#1A6A73] text-white"
                                  : ""
                              } py-2 size-full hover:bg-[#1A6A73] hover:text-white gap-4 text-[#191B1E] items-center text-left`}
                            >
                              <p className="text-[15px] font-semibold">
                                {item.name} (
                                {countriesCodes
                                  .filter(
                                    (data) =>
                                      data.name.toLowerCase() ===
                                      item.name.toLowerCase()
                                  )
                                  ?.map((item, index) => (
                                    <span key={index}>+{item.phone_code}</span>
                                  ))}
                                )
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      onClick={handlePhoneCodeModal}
                      className={`w-screen top-0 left-0 ${
                        phoneCodeModal ? "" : "hidden"
                      }  h-screen fixed z-1`}
                    ></div>
                  </div>
                  <p className="mr-2 text-zinc-500 text-[18px] text-center">
                    |
                  </p>
                  <input
                    type="tel"
                    value={number}
                    maxLength={phoneLength}
                    onChange={handleNumber}
                    className="bg-[#F9F7F3] text-[15px] max-[767px]:text-[14px] font-semibold w-[100%] h-[30px]"
                    placeholder="000000000"
                  />
                </div>
              </div>

              <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
                <label
                  htmlFor="gender"
                  className="block text-[15px] font-semibold text-[#191B1E]"
                >
                  Gender
                </label>
                <select
                  id="gender"
                  name="gender"
                  value={gender}
                  onChange={handleGender}
                  className="mt-1 block w-full h-[30px] pl-2 px-0 text-[15px] max-[767px]:text-[14px] font-semibold py-0  bg-transparent border-none focus:border-none focus:outline-none   sm:text-sm rounded-md"
                >
                  <option value="" disabled>
                    Select your gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {/* <p className="text-[15px] font-semibold text-[#191B1E]">
                  Gender
                </p>
                <div className="w-full relative text-[15px]  cursor-pointer font-semibold  h-[30px] max-[767px]:text-[14px]">
                  <div
                    onClick={handleGenderModal}
                    className="flex justify-between items-center h-full w-full"
                  >
                    <p className="">
                      {gender ? (
                        gender
                      ) : (
                        <p className="text-zinc-400">Select an option</p>
                      )}
                    </p>
                    <img
                      src={arrDown}
                      className="h-[7px] pr-[6px]"
                      alt="menu"
                    />
                  </div>
                  {genderModal ? (
                    <div
                      style={{
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                      }}
                      className="w-full  overflow-y-scroll  z-10 overscroll-none over *:text-left flex flex-col justify-start items-start absolute top-[100%]  rounded-md h-fit max-h-[250px] bg-[#ffffff] border-2 "
                    >
                      <div
                        onClick={() => handleGender("Male")}
                        className={` px-3 ${
                          gender === "Male" ? "bg-[#1A6A73] text-white" : ""
                        } py-1 size-full   text-[#191B1E] `}
                      >
                        Male
                      </div>
                      <div
                        onClick={() => handleGender("Female")}
                        className={` px-3 ${
                          gender === "Female" ? "bg-[#1A6A73] text-white" : ""
                        } py-1 size-full  gap-4 text-[#191B1E] `}
                      >
                        Female
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    onClick={handleGenderModal}
                    className={`w-screen top-0 left-0 ${
                      genderModal ? "" : "hidden"
                    }  h-screen fixed z-1`}
                  ></div>
                </div> */}
              </div>
              <div>
                <button
                  type="submit"
                  className="bg-[#1A6A73] text-center w-[100%] py-[4%] mt-[5%] text-[white] rounded-[30px]"
                >
                  {isLoading ? <FormLoader /> : "Continue"}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </signupDataContext.Provider>
  ) : (
    <div className="mt-[45vh]">
      <Loader />
    </div>
  );
};

export default Signup;
